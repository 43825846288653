<template>
  <div class="app">
    <input type="file" @change="onFileChange" />
    <b-table :data="students" hoverable>
      <b-table-column field="#" label="#" width="40" numeric v-slot="props">
        {{ students.indexOf(props.row) + 1 }}
      </b-table-column>

      <b-table-column label="Surname" v-slot="props">
        {{ props.row.surname }}
      </b-table-column>

      <b-table-column label="Firstname" v-slot="props">{{
        props.row.firstname
      }}</b-table-column>

      <b-table-column label="Othernames" v-slot="props">{{
        props.row.othernames
      }}</b-table-column>

      <b-table-column label="School Class" v-slot="props">
        {{ props.row.school_class }}
      </b-table-column>

      <b-table-column label="Gender" v-slot="props">{{
        props.row.gender
      }}</b-table-column>

      <b-table-column label="Date Of Birth" v-slot="props">
        {{ props.row.date_of_birth }}
      </b-table-column>
    </b-table>
    <button
      type="submit"
      class="button btn-120 is-primary is-capitalized is-pulled-right mt-3"
      @click="initializeBulkUpload()"
    >
      Submit
    </button>
    <app-modal
      id="bulk-upload-student"
      context="add"
      @add="bulkUpload"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'App',
  data() {
    return {
      schoolId: null,
      students: [],
    }
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createInput(files[0])
    },
    createInput(file) {
      let promise = new Promise((resolve, reject) => {
        var reader = new FileReader()
        var vm = this
        reader.onload = (e) => {
          resolve((vm.fileinput = reader.result))
        }
        reader.readAsText(file)
      })

      promise.then(
        (result) => {
          const rows = result.split('\r\n')
          // rows.shift(0)
          rows.forEach((item) => {
            const [
              surname,
              firstname,
              othernames,
              school_class,
              gender,
              date_of_birth,
            ] = item.split(',')
            this.students.push({
              surname: surname.trim(),
              firstname: firstname.trim(),
              othernames: othernames.trim(),
              school_class: school_class.trim(),
              gender: gender.trim(),
              date_of_birth: date_of_birth.trim(),
            })
          })
          // console.log(rows)
        },
        (error) => {
          /* handle an error */
          console.log(error)
        }
      )
    },
    initializeBulkUpload() {
      this.openModal()
    },
    bulkUpload() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation StudentsBulkUploadQuery(
              $school_id: Int!
              $students: JSON!
            ) {
              studentsBulkUpload(
                input: { schoolId: $school_id, students: $students }
              ) {
                errors
              }
            }
          `,
          variables: {
            school_id: this.schoolId,
            students: this.students,
          },
        })
        .then((response) => {
          console.log(response)
          crudNotification(
            response.data.studentsBulkUpload.errors,
            'Successfully updated.'
          )
          this.isModalOpen = false
          this.$router.replace(`/school/${this.schoolId}/students_in_school`)
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', 'Bulk Upload')
    this.$store.commit('setSubMenus', [
      {
        name: 'Students',
        route: `/school/${this.schoolId}/students_in_school`,
      },
    ])
  },
}
</script>
